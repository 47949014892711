
.expand-mainnav {

    .navbar-brand {
        padding:10px 15px 0px;
        background-color:#333;
    }

    .nav-profile-image {
        padding:0 5px 0 0px !important;
        min-height:0 !important;
        background-color:transparent;
        border:0;
        position:relative;
        top:-2px;
        width:auto;
        margin-top:6px;
        margin-bottom:6px;
        margin-left:-2px;
        &:focus{
            outline:none;
        }
        img {
            width:35px;
            height:35px;
        }
    }

    .navbar-toggle {
        background-color: transparent;
        border:solid 1px white;
    }

    .app-search .form-control {
        width: 100%;
        height: 36px;
        padding: 6px 9pt;
        background-color: #fff;
        border: 1px solid #d4dbe0;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }

    .navbar-nav > .active > a {
        background-color: rgba(0,0,0,0.80);
        color: #fff !important;
    }

    .nav > li.active {
        background-color:#333;
    }

    .nav > li a:hover,
    .nav > li a:focus,
    .nav .active > a:hover,
    .nav .active > a:focus {
        background: rgba(0,0,0,0.40);
        color: #fff !important;
    }

    .nav > li > a {
        //padding: 13px 14px;
        /*padding: 10px 15px;*/
        padding-left:15px;
        padding-right:15px;
        color: rgba(255,255,255,.8);
        font-weight:300;
        font-size:14px;
    }
}