$transition-gutter-width: 30px;

$container-sm: 720px + $transition-gutter-width;
$container-md: 960px + $transition-gutter-width;
$container-lg: 1200px + $transition-gutter-width;

$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: $container-lg + $transition-gutter-width;

/* prevent AngularJS blinking */
[ng\:cloak], [ng-cloak], .ng-cloak { display: none; }

/* TODO: these styles need to be addressed */
.TODO-title-single-line {
    line-height: 2.5ex;
    height: 2.5ex;
    overflow:hidden;
}

.TODO-description-three-lines {
    line-height: 2.5ex;
    height: 7.5ex;
    overflow:hidden;
}

.TODO-list-hover:hover {
    background-color: #e8e8e8;
}

.is-mouse-pointer {
    cursor: pointer;
}

.navbar-nav > li:hover {
    background-color: rgba(0,0,0,0.40);
}



.TODO-nav-item-active .navbar-nav>.active>a {
    background-color: rgba(0,0,0,0.80);
}



.stack {
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    border:0;
}

.stack:before {
    box-sizing:border-box;
    content: "";
    height: calc(100% - 23px);
    width: calc(100% - 30px);
    margin:0 0 0 0;
    background: #eff4de;
    border: 6px solid #fff;
    border-radius:5px;
    position: absolute;
    z-index: -1;
    top: 9px;
    left: 23px;
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
}

.stack:after {
    box-sizing:border-box;
    content: "";
    height: calc(100% - 23px);
    width: calc(100% - 30px);
    margin:0 0 0 0;
    background: #eff4de;
    border: 6px solid #fff;
    border-radius:5px;
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 19px;
    -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
}
.stack-label{
    position:absolute;
    text-align:center;
    width:100%;
    bottom:10px;
    color:rgba(0,0,0, 0.50);
}

.stack-with-label{
    margin:0;
    margin-bottom:40px;
}

@media (min-width:768px){
    .stack-with-label.stack:before{
        height: calc(100% - 41px);
    }
    .stack-with-label.stack:after{
        height: calc(100% - 41px);
    }
}

@media (max-width: 767px) {
    .stack-with-label{
        margin:0;
        margin-bottom:60px;
    }
    .stack-label{
        bottom:-33px;
    }
   .stack:before {
        height:100%;
        top:8px;
    }
    .stack:after {
        height:100%;
        top:4px;
    }
}

.expand-checklist {
    h3{
        margin-top:0;
    }

    .checklist-radio {
        padding:5px;
    }

    .checklist-item {
        padding:15px;
    }

    .checklist-item.checklist-item-highlight {
        background-color:#f5f8fa;
    }

    .checklist-item label{
        font-weight:normal;
    }

    .nav-justified>li>a:last-child {
        margin-bottom:0;
    }
}

/* new stuff */

body {
    padding-bottom: 70px;
}

.separator {
    margin:0 10px;
    color:#ccc;
}

.expand-footer {
    width: 100%;
    background-color:transparent;
    font-size:12px;
    text-align:center;
    margin:10px 0px 10px;
    position: absolute;
    bottom: 0;
    /* Set the fixed height of the footer here */
    //height: 40px;
    div {
        margin:5px 0;
    }
}

.powered-by {
    opacity:0.5;
}


.btn-table-link {
    padding:0;
    margin:0;
    border:0;
    outline:none;
}

.expand-transition-container-wrapper {
    margin-left:-15px;
    margin-right:-15px;
}

.expand-transition-container {
    margin-right: auto;
    margin-left: auto;
    padding-left:  ($transition-gutter-width / 2);
    padding-right: ($transition-gutter-width / 2);
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }

    @media (min-width: $screen-sm-min) {
        width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
        width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
        width: $container-lg;
    }
}





@media (max-width: 767px) {
    .top-navigation .navbar-static-top .navbar-header {
        display: block;
        float: none;
    }
}
@media (min-width: 768px) {
    .top-navigation .navbar-static-top .navbar-header {

        float: left;
    }
}

.expand-transition-feed-panel.panel-default>.panel-heading {
    height:200px;
}

.product-imitation{
    background-size:contain;
}

.inline-input { display:inline !important;}
.inline-input input {
    display: inline !important;
    width: auto;
    height: auto;
    margin: 6px 0;
}

.expand-tertiary-nav li {
    padding:5px;
}

.fixed-width-badge {
    width:100px;
    text-align:center;
    display:inline-block;
}

.tabs-container .nav-tabs > li > a {
    margin-right:0;
}
