
@media (min-width: 768px) {
    .expand-profile-header {
        .expand-profile-meta dt{
            width:120px;
        }
        .expand-profile-meta dd{
            margin-left:140px;
        }
    }
}