$expand-image-color: #00931f;
$expand-video-color: #9c0f17;
$expand-document-color: #ff9c00;
$expand-course-color: #985926;
$expand-checklist-color: #006ab7;
$expand-category-color: #888;
$expand-card-bg:#ccc;

.expand-card {
    padding: 0;
    border: 1px solid $border-color;
    position:relative;
    cursor:pointer !important;

    .ibox-title {
        border-top:0;
        border-bottom:1px solid $border-color;
        min-height:40px;
    }

    .ibox-content {
        border:none;
    }

    .expand-media-badge {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
        background-color: #ccc;
        padding: 6px 0 0 1px;
        position: absolute;
        top: -20px;
        left: calc(50% - 17px);
        /* right: 0; */
        border-radius: 50%;
        width: 35px;
        height: 35px;
        text-align: center;
        border: 2px solid $border-color;
        &.image{background-color:$expand-image-color;}
        &.video{background-color:$expand-video-color;}
        &.document{background-color:$expand-document-color;}
        &.course{background-color:$expand-course-color;}
        &.checklist{background-color:$expand-checklist-color;}
        &.category{background-color:$expand-category-color;}
    }

    .card-title {
        max-height: 40px;
        min-height:40px;
        overflow: hidden;
        padding: 2px;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        font-weight: normal;
        line-height: 1.4;
        font-size: 14px;
    }
    .card-cover{
        width:100%;
        min-height:200px;
        background-color:white;
        background-repeat:no-repeat;
        background-position: center center;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-size: contain;
        margin-bottom:80px;
        position:relative;
        z-index:50;
        cursor:pointer;
    }

    .card-desc {
        background-color:white;
        padding: 20px;
        position: relative;
        border-top:1px solid $border-color;
        width:100%;
        position:absolute;
        bottom:0;
        z-index:51;
        cursor:pointer;
    }


    &.item-card:hover,
    &.item-card.active {
      border: 1px solid transparent;
      -webkit-box-shadow: 0 3px 7px 0 rgba(168, 168, 168, 1);
      -moz-box-shadow: 0 3px 7px 0 rgba(168, 168, 168, 1);
      box-shadow: 0 3px 7px 0 rgba(168, 168, 168, 1);
    }

    &.category-card {
        height:280px;

        .ibox-content{
            position:relative;
        }
    }

    &.category-card .card-cover{
        background-color:white;
        height:280px;
        min-height:0;
        padding-bottom:70px;
        margin-bottom:0px;
        -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        -moz-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);

        .card-cover-figure{
            background-repeat:no-repeat;
            background-position: center center;
            /*background-size:100% auto;*/
            -webkit-background-size: contain;
            -moz-background-size: contain;
            -o-background-size: contain;
            background-size: contain;
            width:100%;
            height:100%;
        }
    }
    &.category-card:before,
    &.category-card:after {
        box-sizing:border-box;
        content: "";
        width:calc(100% + 2px);
        height:calc(100% + 2px);
        margin:0 0 0 0;
        background: #fff;
        // border: 6px solid #fff;
        position: absolute;
        -webkit-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        -moz-box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
        box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    }

    &.category-card:before {
        z-index: 48;
        top: 8px;
        left: 7px;
    }

    &.category-card:after {
        z-index: 49;
        top: 4px;
        left: 3px;
    }
}