// Basic Colors
// $navy:              #1ab394;  // Primary color - Original Green
$navy:              #1c84c6;  // Primary color
$dark-gray:         #c2c2c2;  // Default color
$blue:              #1c84c6;  // Success color
$lazur:             #23c6c8;  // Info color
$yellow:            #f8ac59;  // Warrning color
$red:               #c32e29;  // Danger color #a94442 #db3737 #ED5565 #c32e29
$green:             #5cb85c;  // default bootstrap green

$brand-success:     #5cb85c;
$brand-info:        #5bc0de;
$brand-warning:     #f0ad4e;
$brand-danger:      #d9534f;

// Various colors
$text-color: #676a6c;   // Body text
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badget
$label-badget-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spiner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;


// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-backgound: url('patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profil-pattern: url("patterns/header-profile.png") no-repeat;
$nav-text-color: #a7b1c2;