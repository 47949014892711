
.charcoal-bg {
    background-color:#333;
}

.p-0 {
    padding:0 !important;
}

@media (min-width: 767px) {
    .pull-right-sm {
        float:right !important;
    }
    .auto-width-sm {
        width:auto !important;
    }
}

@media (max-width: 767px) {
    .full-width-xs {
        width:100% !important;
    }
}